import { BrowserRouter, Route, Routes } from 'react-router-dom';
import store from './store';
import { Provider as ReduxProvider } from 'react-redux';
import Layout from './components/Layout';
import Customer from './components/widgets/Customer';
import Customers from './components/widgets/Customers';
import People from './components/widgets/People';

import SigninOidc from './components/auth/signin-oidc';
import SignoutOidc from './components/auth/signout-oidc';
import SilentRenewOidc from './components/auth/silent-renew-oidc';
import AccessDenied from './components/AccessDenied';
import ApiFailure from './components/ApiFailure';

import MustAuth from './components/auth/MustAuth';
function App() {
  return (
    <ReduxProvider store={store}>
      <BrowserRouter>
        <Routes>
          <Route exact path="/access-denied" element={<AccessDenied />} />
          <Route exact path="/api-failure" element={<ApiFailure />} />
          <Route exact path="/signout-oidc" element={<SignoutOidc />} />
          <Route exact path="/signin-oidc" element={<SigninOidc />} />
          <Route
            exact
            path="/silent-renew-oidc"
            element={<SilentRenewOidc />}
          />
          <Route element={<MustAuth />}>
            <Route element={<Layout />}>
              <Route path="/" element={<People />} />
              <Route path="/customers" element={<Customers />} />
              <Route path="/customer" element={<Customer />} />
              <Route path="/people" element={<People />} />
            </Route>
          </Route>
        </Routes>
      </BrowserRouter>
    </ReduxProvider>
  );
}

export default App;
