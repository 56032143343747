import { combineReducers } from 'redux';
import auth from './authReducer';
import jobs from './jobReducer';
import comments from './jobCommentReducer';

const rootReducer = combineReducers({
  auth,
  jobs,
  comments,
});

export default rootReducer;
