import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { connect } from 'react-redux';
import { signinRedirect, getUser } from '../../services/userService';
import { storeUser, clearProperties } from '../../actions/authActions';

//import { showLoader, hideLoader } from '../../actions/loaderActions';
//import { toast } from 'react-toastify';
import { Outlet } from 'react-router-dom';
function MustAuth({
  currentUser,
  //location,
  //hideLoader,
  //showLoader,
  //history,
  storeUser,
  clearProperties,
}) {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (currentUser == null) {
      getUser().then((user) => {
        var tokenExpiresAt = new Date(Date.UTC(1970, 0, 1)); // Epoch
        tokenExpiresAt.setSeconds(user?.expires_at);
        if (!user || tokenExpiresAt.getTime() < Date.now()) {
          signinRedirect(`${location.pathname}${location.search}`).catch(
            (e) => {
              navigate('/Access-Denied');
            }
          );
        } else {
          storeUser(user);
        }
      });
    }

    if (currentUser && currentUser.accessDenied) {
      clearProperties().then(() => navigate('/Access-Denied'));
    } else if (currentUser && currentUser.apiFailure) {
      clearProperties().then(() => navigate('/Api-Failure'));
    } else if (currentUser && currentUser.permissionsChanged) {
      clearProperties();
      //.then(() =>
      //toast.warning('Your permissions have been updated')
      //);
    } else if (currentUser && currentUser.isLoading) {
      //showLoader('ALASKA', 'loading...');
    }
    //setTimeout(hideLoader, 1500);
  }, [
    currentUser,
    location.pathname,
    location.search,
    //hideLoader,
    //showLoader,
    navigate,
    storeUser,
    clearProperties,
  ]);

  return currentUser == null ? 'Loading...' : <Outlet />;
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    children: ownProps.children,
  };
}

const mapDispatchToProps = {
  //showLoader,
  //hideLoader,
  storeUser,
  clearProperties,
};

export default //withRouter(
connect(mapStateToProps, mapDispatchToProps)(MustAuth);
//);
