import React, { useEffect, useState, useRef } from 'react';
import { Outlet, useNavigate, useLocation } from 'react-router-dom';
import useCustomerIncompleteJobs from '../../hooks/useCustomerIncompleteJobs';
import { Modal } from 'react-bootstrap';
import Countdown from './Countdown';
import JobDetails from '../JobDetails';
import JobsTable from '../widgets/JobsTable';

export default function WidgetContainer({
  today,
  incompleteJobs,
  commentJobId,
  setCommentJobId,
  tileJobs,
  setTileJobs,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobId, setJobId] = useState();
  const [person, setPerson] = useState();

  const [show, setShow] = useState(false);
  const [showPerson, setShowPerson] = useState(false);
  const [showTile, setShowTile] = useState(false);
  const pauseInterval = 5;
  const [pauseLeft, setPauseLeft] = useState(0);
  const [customers] = useCustomerIncompleteJobs({ incompleteJobs });
  const [customer, setCustomer] = useState();
  const [reset, setReset] = useState(false);

  const timeoutInterval = 15;
  const [timeLeft, setTimeLeft] = useState(timeoutInterval);
  const [items, setItems] = useState([
    { type: 'page', url: '/people' },
    { type: 'page', url: '/customer' },
    { type: 'page', url: '/customers' },
  ]);

  useEffect(() => {
    if (jobId && jobId.length > 1) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [jobId]);

  useEffect(() => {
    if (commentJobId) {
      setJobId(commentJobId);
    }
  }, [commentJobId]);

  const handleClose = () => {
    setJobId();
    setCommentJobId();
  };

  useEffect(() => {
    if (person) {
      setShowPerson(true);
    } else {
      setShowPerson(false);
    }
  }, [person]);
  const handleClosePerson = () => {
    setPerson();
  };
  useEffect(() => {
    if (tileJobs) {
      setShowTile(true);
    } else {
      setShowTile(false);
    }
  }, [tileJobs]);

  useEffect(() => {
    const calculateTimeLeft = () => {
      if (pauseLeft <= 0 && !jobId && !person && !tileJobs) {
        const remaining = timeLeft - 1;
        if (remaining === -1) {
          //change page
          page.current =
            page.current >= items.length - 1 ? 0 : page.current + 1;
          setTimeLeft(timeoutInterval);
          const nextItem = items[page.current];
          if (nextItem.type === 'page') {
            navigate(nextItem.url);
            if (nextItem.url === '/customers') {
              setCustomer(customers[0]);
              page.current = page.current + 1;
            }
          } else {
            setCustomer(customers[nextItem.index]);
          }
        } else {
          if (reset) {
            setTimeLeft(timeoutInterval);
            setReset(false);
          } else {
            setTimeLeft(remaining);
          }
        }
      }
    };

    setTimeout(calculateTimeLeft, 1000);
  });

  useEffect(() => {
    if (location.pathname === '/customers' && !customer && customers) {
      setCustomer(customers[0]);
    }
  }, [location, customer, customers, setCustomer]);

  useEffect(() => {
    if (pauseLeft > 0) {
      setTimeout(() => setPauseLeft((prevState) => prevState - 1), 1000);
    }
  }, [pauseLeft]);

  useEffect(() => {
    if (customers) {
      setItems((prevState) => [
        ...prevState,
        ...customers
          .filter((x) => !prevState.find((y) => y.type === 'customer'))
          .map((x, i) => {
            return { type: 'customer', index: i };
          }),
      ]);
    }
  }, [customers]);
  const page = useRef(0);
  const handleOnClick = () => {
    setPauseLeft(pauseInterval);
  };

  const handlePrevious = (e) => {
    setTimeLeft(timeoutInterval);
    setReset(true);
    setPauseLeft(0);
    if (page.current === 0) {
      page.current = items.length - 1;
      navigate('/customers');
    } else {
      page.current = page.current - 1;
    }
    const nextItem = items[page.current];
    if (nextItem.type === 'page' && nextItem.url !== '/customers') {
      navigate(nextItem.url);
    } else if (nextItem.url === '/customers') {
      handlePrevious();
    } else {
      setCustomer(customers[nextItem.index]);
    }
    e.stopPropagation();
  };

  const handleNext = (e) => {
    page.current = page.current >= items.length - 1 ? 0 : page.current + 1;
    setTimeLeft(timeoutInterval);
    setReset(true);
    setPauseLeft(0);

    const nextItem = items[page.current];
    if (nextItem.type === 'page') {
      navigate(nextItem.url);
      if (nextItem.url === '/customers') {
        setCustomer(customers[0]);
        page.current = page.current + 1;
      }
    } else {
      setCustomer(customers[nextItem.index]);
    }
    e.stopPropagation();
  };
  return (
    <div onClick={handleOnClick} className="content-wrapper">
      <Outlet
        context={[
          today,
          customer,
          setJobId,
          handlePrevious,
          handleNext,
          setPerson,
        ]}
      />
      <Countdown
        timeoutInterval={timeoutInterval}
        remaining={timeLeft}
        pause={pauseLeft}
        reset={reset}
      ></Countdown>
      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-xl modal-fullscreen-xl-down"
      >
        <JobDetails id={jobId} key={jobId} />
      </Modal>

      <Modal
        show={showPerson}
        onHide={handleClosePerson}
        dialogClassName="modal-dialog-centered modal-xl modal-fullscreen-xl-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-light fa-hammer me-2"></i> {person?.name}'s jobs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JobsTable jobs={person?.jobs} setJobId={setJobId}></JobsTable>
        </Modal.Body>
      </Modal>

      <Modal
        show={showTile}
        onHide={() => setTileJobs()}
        dialogClassName="modal-dialog-centered modal-xl modal-fullscreen-xl-down"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <i className="fa-light fa-hammer me-2"></i> {tileJobs?.title} jobs
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <JobsTable jobs={tileJobs?.jobs} setJobId={setJobId}></JobsTable>
        </Modal.Body>
      </Modal>
    </div>
  );
}
