import actionTypes from '../actions/actionTypes';

export function configureSubscriptions(connection, dispatch) {
  connection.on('JobCreated', (job) => {
    dispatch({
      type: actionTypes.JOB_CREATED,
      job,
    });
  });
  connection.on('JobUpdated', (job) => {
    dispatch({
      type: actionTypes.JOB_UPDATED,
      job,
    });
  });
  connection.on('JobDeleted', (job) => {
    dispatch({
      type: actionTypes.JOB_DELETED,
      job,
    });
  });

  connection.on('JobCommentCreated', (comment) => {
    dispatch({
      type: actionTypes.JOB_COMMENT_CREATED,
      comment,
    });
  });
  connection.on('JobCommentUpdated', (comment) => {
    dispatch({
      type: actionTypes.JOB_COMMENT_UPDATED,
      comment,
    });
  });
  connection.on('JobCommentDeleted', (comment) => {
    dispatch({
      type: actionTypes.JOB_COMMENT_DELETED,
      comment,
    });
  });
}
