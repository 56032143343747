import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import PeopleCard from './PeopleCard';
import jobStatuses from '../../actions/jobStatuses';
import Navigation from '../layout/Navigation';

export default function People() {
  const [today, , , handlePrevious, handleNext, setPerson] = useOutletContext();
  const [people, setPeople] = useState();
  useEffect(() => {
    if (today) {
      let result = [];
      today.forEach((item) => {
        let person = result.find((x) => x.id === item.assignedToId);
        if (person) {
          person.todo += item.jobStatusId === jobStatuses.READY ? 1 : 0;
          person.inProgress +=
            item.jobStatusId === jobStatuses.INPROGRESS ? 1 : 0;
          person.done += item.jobStatusId >= jobStatuses.COMPLETE ? 1 : 0;
          person.jobs = [...person.jobs, item];
          let customer = person.customers.find((x) => x.id === item.customerId);
          if (customer) {
            customer.todo += item.jobStatusId === jobStatuses.READY ? 1 : 0;
            customer.inProgress +=
              item.jobStatusId === jobStatuses.INPROGRESS ? 1 : 0;
            customer.done += item.jobStatusId >= jobStatuses.COMPLETE ? 1 : 0;
          } else {
            person.customers.push({
              id: item.customerId,
              name: item.customerName,
              todo: item.jobStatusId === jobStatuses.READY ? 1 : 0,
              inProgress: item.jobStatusId === jobStatuses.INPROGRESS ? 1 : 0,
              done: item.jobStatusId >= jobStatuses.COMPLETE ? 1 : 0,
            });
          }
        } else {
          result.push({
            id: item.assignedToId,
            name: item.assignedTo,
            email: item.assignedToEmail,
            todo: item.jobStatusId === jobStatuses.READY ? 1 : 0,
            inProgress: item.jobStatusId === jobStatuses.INPROGRESS ? 1 : 0,
            done: item.jobStatusId >= jobStatuses.COMPLETE ? 1 : 0,
            customers: [
              {
                id: item.customerId,
                name: item.customerName,
                todo: item.jobStatusId === jobStatuses.READY ? 1 : 0,
                inProgress: item.jobStatusId === jobStatuses.INPROGRESS ? 1 : 0,
                done: item.jobStatusId >= jobStatuses.COMPLETE ? 1 : 0,
              },
            ],
            jobs: [item],
          });
        }
      });
      setPeople(result);
    }
  }, [today]);
  return (
    <>
      <div className="header">
        <h1>People / Jobs Today</h1>
        <div className="branding">
          <img src={'/img/logo.svg'} alt="Bellcorp" />
        </div>
      </div>
      <div className="content people">
        <Navigation
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        ></Navigation>
        {people && people.length > 0 ? (
          <div className="container-fluid g-0">
            <div className="row">
              {people?.map((x) => (
                <div className="col-4" key={x.id} onClick={() => setPerson(x)}>
                  <PeopleCard person={x}></PeopleCard>
                </div>
              ))}
            </div>
          </div>
        ) : (
          <div>No jobs scheduled for today</div>
        )}
      </div>
    </>
  );
}
