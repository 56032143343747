import { useOutletContext } from 'react-router-dom';

import Navigation from '../layout/Navigation';
import JobsTable from './JobsTable';

export default function Customers() {
  const [, customer, setJobId, handlePrevious, handleNext] = useOutletContext();

  return (
    <>
      <div className="header">
        <h1>{customer?.name} Jobs </h1>
        <div className="branding">
          <img src={'/img/logo.svg'} alt="Bellcorp" />
        </div>
      </div>
      <div className="content customers">
        <Navigation
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        ></Navigation>
        <div className="container-fluid g-0">
          <div className="row">
            <div className="col-12">
              {customer && (
                <JobsTable jobs={customer.jobs} setJobId={setJobId}></JobsTable>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
