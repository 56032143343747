import React, { useState, useEffect } from 'react';
import { scheduleJob, assignJob, setJobOrder } from '../actions/jobActions';
import { getAssignableUsers } from '../actions/userActions';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

// https://reactdatepicker.com/
// https://github.com/Hacker0x01/react-datepicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loading from './common/Loading';
import ScheduleJobOrder from './ScheduleJobOrder';

export default function ScheduleJob({
  id,
  scheduledStartDate,
  assignedToId,
  done,
}) {
  const [isSaving, setIsSaving] = useState(false);
  const [showSetOrder, setShowSetOrder] = useState(false);
  const [_scheduledStartDate, _setScheduledStartDate] =
    useState(scheduledStartDate);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    _setScheduledStartDate(data.scheduledStartDate);
    setUserId(data.assignedToId);

    // show the user popup
    if (data.assignedToId) {
      setShowSetOrder(true);
    } else {
      setIsSaving(true);
      assignJob(id, data.assignedToId).then(() => {
        scheduleJob(id, data.scheduledStartDate).then((d) => {
          toast.success('Saved');
          done(d);
          setIsSaving(false);
        });
      });
    }
  };

  const handleScheduleOrderDone = (jobOrderData) => {
    setIsSaving(true);
    setShowSetOrder(false);

    assignJob(id, userId).then(() => {
      scheduleJob(id, _scheduledStartDate).then((d) => {
        setJobOrder(jobOrderData).then((joDone) => {
          toast.success('Saved');
          done(d);
          setIsSaving(false);
        });
      });
    });
  };

  const [userId, setUserId] = useState();
  const [users, setUsers] = useState();
  useEffect(() => {
    if (!users) {
      getAssignableUsers().then((d) => {
        setUsers(d.data);
        if (d.length > 0) {
          setUserId(d[0].userId);
        }
      });
    }
  }, []);

  return users && !isSaving ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-message-plus me-2"></i>Set schedule
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid g-0">
          <div className="form-group row g-0 mb-3">
            <label className="col-4">
              <i className="fa-light fa-user-large me-2 blue"></i>Assigned to
            </label>
            <div className="col-8">
              <select
                {...register('assignedToId')}
                autoFocus
                className="form-control"
                onChange={(e) => setUserId(e.target.value)}
                name="assignedToId"
                defaultValue={assignedToId}
                required
              >
                <option value="">--unassigned--</option>
                {users.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.fullName}
                  </option>
                ))}
              </select>
              {errors.assignedToId && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>
          <div className="form-group row g-0 mb-3">
            <label className="col-4">
              <i className="fa-solid fa-calendar-days me-2 blue"></i>Scheduled
              start date
            </label>
            <div className="col-8">
              <Controller
                control={control}
                required
                defaultValue={
                  scheduledStartDate ? new Date(scheduledStartDate) : new Date()
                }
                name="scheduledStartDate"
                render={({ field }) => (
                  <DatePicker
                    required
                    dateFormat="dd/MMM/yyyy"
                    className="form-control"
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                  />
                )}
              />
              {errors.comment && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-end">
              <button
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary"
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>

      <Modal
        show={showSetOrder}
        onHide={() => setShowSetOrder(false)}
        dialogClassName="modal-dialog-centered modal-lg modal-fullscreen-xl-down"
      >
        <ScheduleJobOrder
          assignedToId={userId}
          scheduledStartDate={_scheduledStartDate}
          jobId={id}
          done={handleScheduleOrderDone}
        />
      </Modal>
    </>
  ) : (
    <Loading></Loading>
  );
}
