import React, { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import jobStatuses from '../../actions/jobStatuses';
import Navigation from '../layout/Navigation';

export default function Customer() {
  const [today, , setJobId, handlePrevious, handleNext] = useOutletContext();
  const [customers, setCustomers] = useState();
  useEffect(() => {
    if (today) {
      let result = [];
      today.forEach((item) => {
        let customer = result.find((x) => x.id === item.customerId);
        if (customer) {
          customer.jobs.push({
            id: item.id,
            description: item.description,
            jobStatusId: item.jobStatusId,
          });
        } else {
          result.push({
            id: item.customerId,
            name: item.customerName,
            jobs: [
              {
                id: item.id,
                description: item.description,
                jobStatusId: item.jobStatusId,
              },
            ],
          });
        }
      });
      setCustomers(result);
    }
  }, [today]);
  return (
    <>
      <div className="header">
        <h1>Customer / Jobs Today</h1>
        <div className="branding">
          <img src={'/img/logo.svg'} alt="Bellcorp" />
        </div>
      </div>
      <div className="content customer-page">
        <Navigation
          handlePrevious={handlePrevious}
          handleNext={handleNext}
        ></Navigation>
        {customers && customers.length > 0 ? (
          <div className="container-fluid g-0">
            <div className="row">
              <div className="col-6">
                {customers.map((customer) => (
                  <div key={customer.id} className="customer">
                    <div className="customer-header">
                      <span>{customer.name}</span>
                    </div>
                    {customer.jobs.map((job) => (
                      <div
                        key={job.id}
                        className="customer-row"
                        onClick={() => setJobId(job.id)}
                      >
                        <span className="job">
                          <span>{job.description}</span>
                        </span>
                        <div className="status">
                          {job.jobStatusId < jobStatuses.COMPLETE && (
                            <div className="todo">Todo</div>
                          )}
                          {job.jobStatusId >= jobStatuses.COMPLETE && (
                            <div className="done">Done</div>
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <div>No jobs scheduled for today</div>
        )}
      </div>
    </>
  );
}
