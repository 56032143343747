import * as jobCommentsApi from '../api/jobCommentsApi';
export function getComments({
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  return jobCommentsApi.getComments(
    filters,
    _scopes,
    _sort,
    _order,
    _page,
    _limit
  );
}
export function loadCommentsForJob(id) {
  return jobCommentsApi.getCommentsForJob(id);
}

export function getCommentById(id) {
  return jobCommentsApi.getCommentById(id);
}

export function saveComment(comment) {
  return jobCommentsApi.saveComment(comment);
}

export function deleteComment(id) {
  return jobCommentsApi.deleteComment(id);
}
