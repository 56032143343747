import {
  apiUrl,
  deleteRecord,
  getAll,
  getList,
  getSingleRecord,
  saveRecord,
} from './apiUtils';
const baseUrl = apiUrl + '/jobComments/';

export function getComments(filters, _scopes, _sort, _order, _page, _limit) {
  return getList(baseUrl, filters, _scopes, _sort, _order, _page, _limit);
}

export function getCommentsForJob(id) {
  let url = `${baseUrl}job/${id}`;
  return getAll(url);
}

export function getCommentById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveComment(job) {
  return saveRecord(baseUrl, job);
}

export function deleteComment(id) {
  return deleteRecord(baseUrl, id);
}
