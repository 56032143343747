import Gravatar from 'react-gravatar';

function PeopleCard({ person }) {
  return (
    <div className="person">
      <div className="person-header">
        <span className="user d-flex align-items-center  text-truncate">
          <Gravatar
            email={person.email}
            size={34}
            rating="pg"
            default="retro"
            className="CustomAvatar-image"
          />
          <span className="d-inline-block text-truncate pe-2">
            {person.name}
          </span>
        </span>
        <div className="jobs-count">
          <div className="todo">
            <i className="fa-light fa-hammer"></i>
          </div>
          <div className="done">
            <i className="fa-light fa-circle-check"></i>
          </div>
        </div>
      </div>
      {person.customers.map((x) => (
        <div
          className={`person-row  ${x.inProgress > 0 && 'in-progress'}`}
          key={x.id}
        >
          <span
            className={`customer d-flex align-items-center  text-truncate ${
              x.inProgress > 0 && 'in-progress'
            }`}
          >
            <span className="d-inline-block text-truncate pe-2">{x.name}</span>
          </span>
          <div className="jobs-count">
            <div className="todo">
              <span className="number">{x.todo}</span>
            </div>
            <div className="done">
              <span className={`number ${x.done === 0 ? 'none' : ''}`}>
                {x.done === 0 ? '--' : x.done}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}
export default PeopleCard;
