function Countdown({ timeoutInterval, remaining, pause, reset }) {
  const percentage = reset ? 0 : 100 - (100 / timeoutInterval) * remaining;
  return (
    <div
      className={`progressBar ${
        remaining !== timeoutInterval && pause <= 0 ? 'animate' : ''
      }`}
      style={{ width: '100%', height: '5px' }}
    >
      <div
        style={{
          width: `${percentage}%`,
          backgroundColor: pause > 0 ? '#4e4e4e' : '#3dbaec',
          height: '100%',
        }}
      ></div>
    </div>
  );
}
export default Countdown;
