const actionTypes = {
  STORE_USER: 'STORE_USER',
  USER_SIGNED_OUT: 'USER_SIGNED_OUT',
  USER_EXPIRED: 'USER_EXPIRED',
  STORE_USER_ERROR: 'STORE_USER_ERROR',
  LOADING_USER: 'LOADING_USER',
  ACCESS_DENIED: 'ACCESS_DENIED',
  USER_SIGNED_IN: 'USER_SIGNED_IN',
  API_FAILURE: 'API_FAILURE',
  TOKEN_REFRESHED: 'TOKEN_REFRESHED',
  USER_PERMISSIONS_CHANGED: 'USER_PERMISSIONS_CHANGED',
  USER_CLEAR_PROPERTIES: 'USER_CLEAR_PROPERTIES',
  JOB_CREATED: 'JOB_CREATED',
  JOB_UPDATED: 'JOB_UPDATED',
  JOB_DELETED: 'JOB_DELETED',
  JOB_COMMENT_CREATED: 'JOB_COMMENT_CREATED',
  JOB_COMMENT_UPDATED: 'JOB_COMMENT_UPDATED',
  JOB_COMMENT_DELETED: 'JOB_COMMENT_DELETED',
};
export default actionTypes;
