import { Date_AsString } from '../../util/formatter';
import Gravatar from 'react-gravatar';
function JobsTable({ jobs, setJobId }) {
  return (
    <table className="table dataTable width-100">
      <thead>
        <tr>
          <th className="wp-10">Work order #</th>
          <th className="wp-30">Location / Description</th>
          <th className="wp-10">Scheduled/Issued</th>
          <th className="wp-10">Complete</th>
          <th className="wp-30">Assigned to</th>
        </tr>
      </thead>
      <tbody>
        {jobs?.map((job) => (
          <tr key={job.id} onClick={() => setJobId(job.id)}>
            <td className="text-white">{job.workOrder}</td>
            <td>
              <div className="location mb-2">{job.location}</div>
              <div className="desc">{job.description}</div>
            </td>
            <td>
              <div className="d-block mb-2">
                <div className="label scheduled">
                  <span className="w-20 green">
                    <i className="fa-light fa-hammer"></i>
                  </span>
                  {Date_AsString(job.scheduledStartDate, '', true)}
                </div>
              </div>
              <div className="d-block mb-2">
                <div className="label issued">
                  <span className="w-20 blue">
                    <i className="fa-light fa-calendar-days"></i>{' '}
                  </span>
                  {Date_AsString(job.receivedFromCustomerDate)}
                </div>
              </div>
              <div className="d-block mb-2">
                <div className="label turnaround">
                  <span className="w-20 blue">
                    <i className="fa-light fa-rotate-right"></i>
                  </span>
                  {job.timescaleDays} days
                </div>
              </div>
            </td>
            <td>{Date_AsString(job.completedDate)}</td>
            <td>
              {job.assignedToEmail && (
                <div className="assigned mb-3">
                  <Gravatar
                    email={job.assignedToEmail}
                    size={20}
                    rating="pg"
                    default="retro"
                    className="CustomAvatar-image"
                  />
                  <span> {job.assignedTo}</span>
                </div>
              )}
              {job.lastComment && (
                <div className="job-comment">"{job.lastComment}"</div>
              )}
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
export default JobsTable;
