import React, { useState, useEffect } from 'react';
import Gravatar from 'react-gravatar';
import { Modal } from 'react-bootstrap';
import { DateAndTime_AsString_NoHtml } from '../util/formatter';
import Loading from './common/Loading';
import AddComment from './AddComment';
import { deleteComment } from '../actions/jobCommentActions';
import AttachmentImage from './widgets/AttachmentImage';

export default function Comments({ job, jobComments, reload }) {
  const [show, setShow] = useState(false);

  const handleClose = () => {
    setShow(false);
  };
  const handleAddCommentDone = () => {
    setShow(false);
    reload();
  };

  const handleDeleteComment = (id) => {
    deleteComment(id).then(() => {
      reload();
    });
  };

  return jobComments ? (
    <>
      <h2 className="mt-3 pb-2 d-flex justify-content-between align-items-center">
        <span>History</span>
        <button className="btn btn-secondary" onClick={() => setShow(true)}>
          Add comment
        </button>
      </h2>

      <Modal
        show={show}
        onHide={handleClose}
        dialogClassName="modal-dialog-centered modal-md comments-modal"
      >
        <AddComment id={job.id} done={handleAddCommentDone} />
      </Modal>

      {jobComments.length > 0 ? (
        jobComments.map((x) => (
          <div className="history-item" key={x.jobCommentId}>
            <div className="group">
              <div className=" d-flex pb-2">
                <div className="user me-3">
                  <Gravatar
                    email={x.createdByEmailAddress}
                    size={34}
                    rating="pg"
                    default="retro"
                    className="CustomAvatar-image"
                  />
                  <span> {x.CreatedBy}</span>
                </div>
                <div className="pt-1">
                  <span>{x.comment}</span>
                  {x.jobAttachmentDtos &&
                    x.jobAttachmentDtos.length > 0 &&
                    x.jobAttachmentDtos.map((photo) => (
                      <AttachmentImage
                        key={photo.jobAttachmentId}
                        alt={photo.description}
                        id={photo.jobAttachmentId}
                      />
                    ))}
                </div>
              </div>
              <div className="date d-flex justify-content-between">
                <div className="">
                  {DateAndTime_AsString_NoHtml(x.updatedDate ?? x.creationDate)}
                </div>
                <span
                  className="a"
                  onClick={() => handleDeleteComment(x.jobCommentId)}
                >
                  Delete
                </span>
              </div>
            </div>
          </div>
        ))
      ) : (
        <div>No comments</div>
      )}
    </>
  ) : (
    <Loading></Loading>
  );

  //   <div className="history-item">
  //     <div className="group">
  //       <div className=" d-flex pb-2">
  //         <div className="user me-3">
  //           <i className="fa-light fa-user"></i>
  //         </div>
  //         <div className="pt-1">
  //           <span>Job started by Alan</span>
  //         </div>
  //       </div>
  //       <div className="date">
  //         <div>{Date_AsString(job.updatedDate)}</div>
  //         <div>{Date_AsString(job.creationDate)}</div>
  //       </div>
  //     </div>
  //   </div>

  //   <div className="history-item">
  //     <div className="group">
  //       <div className=" d-flex pb-2">
  //         <div className="user me-3">
  //           <i className="fa-light fa-user"></i>
  //         </div>
  //         <div className="pt-1">
  //           <span>Job started by Alan</span>
  //         </div>
  //       </div>
  //       <div className="date">
  //         <div>{Date_AsString(job.updatedDate)}</div>
  //         <div>{Date_AsString(job.creationDate)}</div>
  //       </div>
  //     </div>
  //   </div>

  //   <div className="history-item">
  //     <div className="group">
  //       <div className=" d-flex pb-2">
  //         <div className="user me-3">
  //           <i className="fa-light fa-user"></i>
  //         </div>
  //         <div className="pt-1">
  //           <div>Job started by Alan</div>
  //           <img src="/img/image1.jpg" />
  //           <img src="/img/image1.jpg" />
  //         </div>
  //       </div>
  //       <div className="date">
  //         <div>{Date_AsString(job.updatedDate)}</div>
  //         <div>{Date_AsString(job.creationDate)}</div>
  //       </div>
  //     </div>
  //   </div>
  //);
}
