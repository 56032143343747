import React, { useState, useEffect } from 'react';
import { startJob } from '../actions/jobActions';
import { getAssignableUsers } from '../actions/userActions';
import { Controller, useForm } from 'react-hook-form';
import { Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';

// https://reactdatepicker.com/
// https://github.com/Hacker0x01/react-datepicker
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import Loading from './common/Loading';

export default function StartJob({ id, startedDate, startedByUserId, done }) {
  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    setIsSaving(true);
    startJob(id, data.startedDate, data.startedByUserId).then((d) => {
      toast.success('Saved');
      done(d);
      setIsSaving(false);
    });
  };

  const [users, setUsers] = useState();
  useEffect(() => {
    if (!users) {
      getAssignableUsers().then((d) => {
        setUsers(d.data);
      });
    }
  }, []);

  return users && !isSaving ? (
    <>
      <Modal.Header closeButton>
        <Modal.Title>
          <i className="fa-light fa-circle-play me-2"></i>Start job
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="container-fluid g-0">
          <div className="form-group row g-0 mb-3">
            <label className="col-4">
              <i className="fa-light fa-user-large me-2 blue"></i>Started by
            </label>
            <div className="col-8">
              <select
                {...register('startedByUserId')}
                autoFocus
                className="form-control"
                name="startedByUserId"
                defaultValue={startedByUserId}
                required
              >
                {users.map((x) => (
                  <option key={x.id} value={x.id}>
                    {x.fullName}
                  </option>
                ))}
              </select>
              {errors.startedByUserId && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>
          <div className="form-group row g-0 mb-3">
            <label className="col-4">
              <i className="fa-solid fa-calendar-days me-2 blue"></i>Started
              date
            </label>
            <div className="col-8">
              <Controller
                control={control}
                required
                defaultValue={startedDate ? new Date(startedDate) : new Date()}
                name="startedDate"
                render={({ field }) => (
                  <DatePicker
                    required
                    dateFormat="dd/MMM/yyyy HH:mm"
                    className="form-control"
                    placeholderText="Select date"
                    onChange={(date) => field.onChange(date)}
                    selected={field.value}
                    showTimeSelect
                  />
                )}
              />
              {errors.comment && (
                <span className="field-validation-error">Required</span>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col d-flex justify-content-end">
              <button
                onClick={handleSubmit(onSubmit)}
                className="btn btn-primary"
                disabled={isSaving}
              >
                Save
              </button>
            </div>
          </div>
        </div>
      </Modal.Body>
    </>
  ) : (
    <Loading></Loading>
  );
}
