import React, { useEffect, useState } from 'react';

import jobStatuses from '../../actions/jobStatuses';
export default function TodayPanel({ today, setTileJobs }) {
  const [ready, setReady] = useState(0);
  const [inProgress, setInProgress] = useState(0);
  const [done, setDone] = useState(0);
  const [reschedule, setReschedule] = useState(0);
  const [secondVisit, setSecondVisit] = useState(0);

  useEffect(() => {
    if (today) {
      setReady(
        today.filter((item) => item.jobStatusId === jobStatuses.READY).length
      );
      setInProgress(
        today.filter((item) => item.jobStatusId === jobStatuses.INPROGRESS)
          .length
      );
      setDone(
        today.filter((item) => item.jobStatusId >= jobStatuses.COMPLETE).length
      );
      setReschedule(
        today.filter(
          (item) => item.jobStatusId === jobStatuses.NEEDSRESCHEDULING
        ).length
      );
      setSecondVisit(
        today.filter(
          (item) => item.jobStatusId === jobStatuses.NEEDSSECONDVISIT
        ).length
      );
    }
  }, [today]);

  const handleOnClick = (title, status, greaterThan) => {
    setTileJobs({
      title,
      jobs: greaterThan
        ? today?.filter((item) => item.jobStatusId >= status)
        : today?.filter((item) => item.jobStatusId === status),
    });
  };

  return (
    <>
      <div className="today-panel">
        <h2>Today</h2>
        <div className="today-row">
          <div
            className="today-card"
            onClick={() => handleOnClick('Ready', jobStatuses.READY)}
          >
            <div className="number orange">
              {ready}
              <span>Ready</span>
            </div>
            <i className="fa-light fa-hammer"></i>
          </div>
          <div
            className="today-card"
            onClick={() => handleOnClick('In Progress', jobStatuses.INPROGRESS)}
          >
            <div className="number blue">
              {inProgress}
              <span>In Progress</span>
            </div>
            <i className="fa-light fa-hourglass"></i>
          </div>
          <div
            className="today-card"
            onClick={() => handleOnClick('Done', jobStatuses.COMPLETE, true)}
          >
            <div className="number green">
              {done}
              <span>Done</span>
            </div>
            <i className="fa-light fa-circle-check"></i>
          </div>
          <div
            className="today-card"
            onClick={() =>
              handleOnClick('Needs Reschedule', jobStatuses.NEEDSRESCHEDULING)
            }
          >
            <div className="number white">
              {reschedule}
              <span>Needs Reschedule</span>
            </div>
            <i className="fa-light fa-calendar-clock"></i>
          </div>
          <div
            className="today-card"
            onClick={() =>
              handleOnClick('Needs Second Visit', jobStatuses.NEEDSSECONDVISIT)
            }
          >
            <div className="number text-colour">
              {secondVisit}
              <span>Needs Second Visit</span>
            </div>
            <i className="fa-light fa-person-walking"></i>
          </div>
        </div>
      </div>
    </>
  );
}
