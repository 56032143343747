import dateRanges from '../actions/dateRanges';
import {
  apiUrl,
  getList,
  getSingleRecord,
  putAction,
  saveRecord,
  get,
} from './apiUtils';
const baseUrl = apiUrl + '/jobs/';

export function getJobs(filters, _scopes, _sort, _order, _page, _limit) {
  //let url = `${baseUrl}list?fromDate=${fromDate.toISOString()}&toDate=${toDate.toISOString()}`;
  let url = `${baseUrl}list`;
  return getList(url, filters, _scopes, _sort, _order, _page, _limit);
}

export function getJobById(id) {
  return getSingleRecord(baseUrl, id);
}

export function saveJob(job) {
  return saveRecord(baseUrl, job);
}

export function assignJob(jobid, userid) {
  if (userid) {
    return putAction(`${baseUrl}${jobid}/assign/${userid}`);
  }
  return putAction(`${baseUrl}${jobid}/unassign`);
}

export function scheduleJob(jobid, scheduledDate) {
  return putAction(
    `${baseUrl}${jobid}/schedule/${scheduledDate.toISOString()}`
  );
}

export function setJobOrder(jobAndOrder) {
  return putAction(`${baseUrl}joborder`, { items: jobAndOrder });
}

export function getJobStats(jobStatus, dateRange) {
  let _scopes = {};
  _scopes.status = jobStatus;
  _scopes.dateRange = dateRange === dateRanges.ALL ? null : dateRange;

  return getList(`${baseUrl}stats`, {}, _scopes, null, null, 0, 0);
}

export function startJob(jobid, startedDate, startedByUserId) {
  return putAction(
    `${baseUrl}${jobid}/start/${startedDate.toISOString()}/${startedByUserId}`
  );
}

export function stopJob(jobid, stoppedDate, stoppedByUserId) {
  return putAction(
    `${baseUrl}${jobid}/stop/${stoppedDate.toISOString()}/${stoppedByUserId}`
  );
}

export function saveJobFinance(jobid, costOfMaterials, totalDurationMinutes) {
  return putAction(`${baseUrl}${jobid}/finance`, {
    costOfMaterials,
    totalDurationMinutes,
  });
}

export function saveJobInvoiceNumber(jobid, invoiceNumber) {
  return putAction(`${baseUrl}${jobid}/invoice`, {
    invoiceNumber,
  });
}
