import { connect } from 'react-redux';
import React, { useEffect, useState } from 'react';

import { loadJobs } from '../actions/jobActions';
import { getComments } from '../actions/jobCommentActions';
import dateRanges from '../actions/dateRanges';
import jobStatuses from '../actions/jobStatuses';
import RightPanel from './layout/RightPanel';
import TodayPanel from './layout/TodayPanel';
import WidgetContainer from './layout/WidgetContainer';

function Layout({ stateJobs, stateComments }) {
  const [today, setToday] = useState();
  const [reload, setReload] = useState();
  const [incompleteJobs, setIncompleteJobs] = useState();

  const [comments, setComments] = useState();
  const [reloadComments, setReloadComments] = useState();

  const [commentJobId, setCommentJobId] = useState();

  const [tileJobs, setTileJobs] = useState();

  useEffect(() => {
    loadJobs({
      status: null,
      filters: null,
      _scopes: { dateRange: dateRanges.TODAY },
      _sort: '',
      _order: '',
      _page: 1,
      _limit: 100,
    }).then((d) => {
      setToday(d.data);
    });
  }, [reload]);

  useEffect(() => {
    if (stateJobs) {
      setReload(new Date());
    }
  }, [stateJobs]);

  useEffect(() => {
    if (stateComments) {
      setReloadComments(new Date());
    }
  }, [stateComments]);

  useEffect(() => {
    getComments({
      status: null,
      filters: null,
      _scopes: null,
      _sort: 'creationDate',
      _order: 'desc',
      _page: 1,
      _limit: 25,
    }).then((d) => {
      setComments(d.data);
    });
  }, [reloadComments]);

  useEffect(() => {
    loadJobs({
      status: null,
      filters: null,
      _scopes: { maxStatus: jobStatuses.COMPLETE },
      _sort: 'updatedDate',
      _order: 'desc',
      _page: 1,
      _limit: 10000,
    }).then((d) => {
      setIncompleteJobs(d.data);
    });
  }, [reload]);

  return (
    <main>
      <div className="content-wrapper">
        <WidgetContainer
          today={today}
          incompleteJobs={incompleteJobs}
          commentJobId={commentJobId}
          setCommentJobId={setCommentJobId}
          tileJobs={tileJobs}
          setTileJobs={setTileJobs}
        ></WidgetContainer>
        <TodayPanel today={today} setTileJobs={setTileJobs}></TodayPanel>
      </div>
      <RightPanel
        comments={comments}
        setCommentJobId={setCommentJobId}
      ></RightPanel>
    </main>
  );
}

function mapStateToProps(state, ownProps) {
  return {
    currentUser: state.auth,
    stateJobs: state.jobs,
    stateComments: state.comments,
  };
}

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
