import * as jobApi from '../api/jobApi';

export function loadJobs({
  status,
  filters,
  _scopes,
  _sort,
  _order,
  _page,
  _limit,
}) {
  _scopes.status = status;
  return jobApi.getJobs(filters, _scopes, _sort, _order, _page, _limit);
}

export function getJobById(id) {
  return jobApi.getJobById(id);
}

export function saveJob(job) {
  return jobApi.saveJob(job);
}

export function assignJob(jobid, userid) {
  if (userid === '') {
    userid = null;
  }
  return jobApi.assignJob(jobid, userid);
}

export function scheduleJob(jobid, scheduledDate) {
  return jobApi.scheduleJob(jobid, scheduledDate);
}

export function getJobStats(jobStatus, dateRange) {
  return jobApi.getJobStats(jobStatus, dateRange);
}

export function setJobOrder(jobAndOrder) {
  return jobApi.setJobOrder(jobAndOrder);
}

export function startJob(jobid, startedDate, startedByUserId) {
  return jobApi.startJob(jobid, startedDate, startedByUserId);
}

export function stopJob(jobid, stoppedDate, stoppedByUserId) {
  return jobApi.stopJob(jobid, stoppedDate, stoppedByUserId);
}

export function saveJobFinance(jobid, costOfMaterials, totalDurationMinutes) {
  return jobApi.saveJobFinance(jobid, costOfMaterials, totalDurationMinutes);
}

export function saveJobInvoiceNumber(jobid, invoiceNumber) {
  return jobApi.saveJobInvoiceNumber(jobid, invoiceNumber);
}
