import Gravatar from 'react-gravatar';
import { DateAndTime_AsString_NoHtml } from '../../util/formatter';
import AttachmentImage from '../widgets/AttachmentImage';

export default function RightPanel({ comments, setCommentJobId }) {
  const Comment = ({ x }) => {
    return (
      <div className="update-item" onClick={() => setCommentJobId(x.jobId)}>
        <div className="update">
          {x.comment}{' '}
          {x.jobAttachments &&
            x.jobAttachments.length > 0 &&
            x.jobAttachments.map((photo) => (
              <AttachmentImage
                key={photo.id}
                alt={photo.description}
                id={photo.id}
              />
            ))}
        </div>

        <div className="update-info">
          <span>
            <Gravatar
              email={x.createdByEmailAddress}
              size={34}
              rating="pg"
              default="retro"
              className="user me-2"
            />
            {x.createdBy}
          </span>
          <span>
            {DateAndTime_AsString_NoHtml(x.updatedDate ?? x.creationDate)}
          </span>
        </div>
      </div>
    );
  };

  return (
    <div className="right-panel">
      <div className="header">
        <h1>Updates</h1>
      </div>
      <div className="right-panel-scroll">
        {comments?.map((comment) => (
          <Comment key={comment.jobCommentId} x={comment}></Comment>
        ))}
      </div>
    </div>
  );
}
