import { useEffect, useState } from 'react';
const useCustomerIncompleteJobs = ({ incompleteJobs }) => {
  const [customers, setCustomers] = useState();
  useEffect(() => {
    if (incompleteJobs) {
      let result = [];
      incompleteJobs.forEach((item) => {
        let customer = result.find((x) => x.id === item.customerId);
        if (customer) {
          customer.jobs.push(item);
        } else {
          result.push({
            id: item.customerId,
            name: item.customerName,
            jobs: [item],
          });
        }
      });
      setCustomers(result);
    }
  }, [incompleteJobs]);
  return [customers];
};
export default useCustomerIncompleteJobs;
