import React, { useState, useEffect, useRef } from 'react';
import { getAttachmentBase64 } from '../../actions/jobAttachmentActions';
export default function AttachmentImage({ id, alt }) {
  const [img, setImg] = useState();
  const mountedRef = useRef(true);

  useEffect(() => {
    const fetchImage = async () => {
      getAttachmentBase64(id).then((d) => {
        if (!mountedRef.current) return null;
        setImg(d);
      });
    };
    if (id) {
      fetchImage();
    }
  }, [id]);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  return img ? <img src={img} alt={alt} className="img-fluid" /> : '';
}
